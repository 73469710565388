<template>
    <div class="Airdrop">
        <div class="banner">
            <div class="bannerImg">
                <img class="bg1" src="@/assets/Airdrop/banner.png" alt="">
            </div>
            <div class="center connectBoxW">
                <div class="bigTitle">
                    <h1>$ZK <span>Airdrop</span></h1>
                    <h2>Learn about the whole Incentive Program that includes Retroactive ZK Airdrop, Protocol Growth Incentive and Community Growth Incentive.</h2>
                   
                </div>
            </div>
        </div>
        <div class="rule">
            <div class="rulebg"></div>
            <div class="center connectBoxW">
                <div class="title">
                    <p>SpaceFi is launching Retroactive ZK Airdrop for community members who contributed in our <br />previous growth. It will cover users that:</p>
                </div>
                <div class="border"></div>
                <ul>
                    <li>
                        <div>1</div>
                        <h4>SpaceFi 1.0</h4>
                        <p>Earned SpaceFi Points</p>
                    </li>
                    <li>
                        <div>2</div>
                        <h4>SpaceFi 2.0</h4>
                        <p>Add and Hold some Liquidity <br /> Hold bLP, Planet NFT or xSTAR <br /> Create or Join a Spacebase</p>
                    </li>
                    <li>
                        <div>3</div>
                        <h4>Active Community Contributors</h4>
                        <p>As announced in our discord before</p>
                    </li>
                </ul>
            </div>
        </div>
        <div class="retroactive">
            <div class="center connectBoxW">
                <div class="title">
                    <h4>Your Retroactive ZK Airdrop:</h4>
                </div>
                <div class="info">
                    <div class="claim" v-if="ifClaim">
                        <div>
                            <p v-if="!ifClaimed">{{ claimNum }} <span>ZK</span></p>
                            <p v-else>{{ claimedNum }} <span>ZK</span></p>
                            <div v-if="!ifClaimed" class="btn" @click="claim">Claim <v-LoadingWarp v-if="claimLoading" :imgSize="1"></v-LoadingWarp></div>
                            <div v-else class="btn btned">Claimed</div>
                        </div>
                    </div>
                    <div class="noClaim" v-else>
                        <p>Sorry that you're not included in Retroactive ZK Airdrop.</p>
                    </div>
                </div>
            </div>
            
        </div>
        <div class="through">
            <div class="center connectBoxW">
                <div class="title">
                    <p>You can also Earn ZK Incentive through:</p>
                </div>
                <ul>
                    <li>
                        <h6>1. Protocol Growth: </h6>
                        <div class="border"></div>
                        <p>Join ZK Fest, Deposit LP in SpaceFi Farm to earn both $ZK and $xSPACE double rewards.</p>
                        <router-link class="btn" tag="a" to="/Farm">Join</router-link>
                    </li>
                    <li>
                        <h6>2. Community Growth Incentive: </h6>
                        <div class="border"></div>
                        <p>Join SpaceFi Discord to be an Active Contributor.</p>
                        <a class="btn" href="https://discord.com/invite/nezjCa5j8r" target="_block">Join Discord</a>
                    </li>
                </ul>
            </div>
        </div>
        <v-Footer></v-Footer>
        <message-model ref="messageModel"></message-model>
    </div>
</template>

<script>
import messageModel from '@/components/Message.vue'
import { judgeNetwork, getAirdropAward, getAirdropUserReceive, claimAirdrop } from "@/utils/space.js"
import {numberToFixed} from '@/utils/function.js'
export default {
    name: "Airdrop",
	components:{
		messageModel
	},
    data() {
        return {
            ifClaim:false,
            claimNum:0,
            claimNumber:0,
            ifClaimed:false,
            claimedNum:0,
            claimLoading:false
        }
    },
    created() { },
    async mounted() {
        this.userAddress = localStorage.getItem('userAddress')
        this.zkairdropAddress = window.contractAddr.zkairdropAddress
        getAirdropUserReceive(this.zkairdropAddress,this.userAddress).then(receiveres => {
            if (receiveres == 0) {
                getAirdropAward(this.zkairdropAddress,this.userAddress).then(awardres => {
                    this.ifClaim = awardres == 0 ? false : true
                    this.claimNum = numberToFixed(this.$BigNumber(awardres).div(Math.pow(10,18)),6)
                    this.claimNumber = this.$BigNumber(awardres).div(Math.pow(10,18))
                })
            } else {
                this.ifClaim = true
                this.ifClaimed = receiveres == 0 ? false : true
                this.claimedNum = numberToFixed(this.$BigNumber(receiveres).div(Math.pow(10,18)),6)
            }
        })
    },
    methods: {
        claim(){
            if(this.claimLoading){
                return
            }
            if (this.userAddress == '') {
                this.$notify({type:'warning',message:'Please connect wallet first!'})
                return false
            }
            this.claimLoading = true
            judgeNetwork().then(res => {
                if (res == 0) {
                    this.claimLoading = false
                    return false
                }
                claimAirdrop(this.zkairdropAddress,this.userAddress).then(res => {
                    this.claimLoading = false
                    this.ifClaimed = true
                    this.claimedNum = this.claimNum
                    var obj = {
                        messageShow:true,
                        starAvailable:this.claimNumber,
                        xstarAvailable:0,
                        oneAvailable:0,
                        twoAvailable:0,
                        thrAvailable:0,
                        coin:{
                            star:{name:'ZK',contract:'0x5A7d6b2F92C77FAD6CCaBd7EE0624E64907Eaf3E'},
                            xstar:{name:'',contract:''},
                            one:{name:'',contract:''},
                            two:{name:'',contract:''},
                            thr:{name:'',contract:''},
                        },
                        resHash:res,
                    }
		            this.$refs.messageModel.open(obj)
                }).catch(err => {
                    this.claimLoading = false
                })
            })
        }
    }
}
</script>